import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Store} from "@ngxs/store";
import {Observable} from "rxjs";
import {filter, map} from "rxjs/operators";
import {NotificationsFacade} from "../../notifications/NotificationsFacade";
import {IAuthStateModel} from "../api/IAuthStateModel";
import {AuthQueries} from "../redux/AuthQueries";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  private readonly auth$: Observable<IAuthStateModel> = inject(Store).select(AuthQueries.auth);

  constructor(
    private notify: NotificationsFacade,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot) {
    return this.auth$.pipe(
      // Wait for auth to be synced
      filter(auth => auth.synced),
      // Check if user is logged in
      map(auth => {
        const loggedIn = AuthQueries.isloggedIn(auth);
        if (!loggedIn) {
          // Return immediately if user has not logged into the app
          console.info("AuthGuard", "User not logged in");
          this.notify.warn("Ej inloggad!", "Vänligen logga in");
          return false;
        } else {
          const userRoles = auth.roles;
          const routeRoles = route.data["roles"] || [];
          // Check if user has correct roles
          const canAccess = routeRoles.some((it: string) => userRoles.includes(it));
          console.info(
            "AuthGuard",
            `User "${auth.username}" ${canAccess ? 'granted' : 'denied'} access to ${routerStateSnapshot.url}`,
          );
          return canAccess;
        }
      }),
    );
  }

}
