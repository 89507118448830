import {Credentials} from "../api/Credentials";

export namespace AuthActions {

  export class LoginAction {
    static readonly type = "[Auth] login";

    constructor(public credentials: Credentials) {
    }
  }

  export class LogoutAction {
    static readonly type = "[Auth] logout";
  }

  export class CheckLoginStatusAction {
    static readonly type = "[Auth] check status ";
  }

}
