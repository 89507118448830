import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Credentials} from "../api/Credentials";
import {Whoami} from "../api/whoami";

@Injectable()
export class SecurityService {
  constructor(private http: HttpClient) {
  }

  whoami(): Observable<Whoami> {
    return this.http.get<Whoami>("/api/whoami");
  }

  public login(credentials: Credentials): Observable<Whoami> {
    const authorization = "Basic " + btoa(`${credentials.username}:${credentials.password}`);
    const headers = new HttpHeaders(credentials ? {authorization} : {});
    return this.http.get<Whoami>("/api/secured", {headers});
  }

  public logout(): Observable<void> {
    return this.http.post<void>("/j_spring_security_logout", {});
  }
}
