import {Selector} from "@ngxs/store";
import {IAuthStateModel} from "../api/IAuthStateModel";
import {AuthState} from "./AuthState";

export class AuthQueries {

  @Selector([AuthState])
  static auth(state: IAuthStateModel): IAuthStateModel {
    return state;
  }

  @Selector([AuthState])
  static isloggedIn(state: IAuthStateModel): boolean {
    return state.roles.length > 0 && !!state.username;
  }

}
