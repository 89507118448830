import {Injectable} from "@angular/core";
import {Action, State, StateContext} from "@ngxs/store";
import {tap} from "rxjs/operators";
import {IAuthStateModel} from "../api/IAuthStateModel";
import {SecurityService} from "../service/SecurityService";
import {AuthActions} from "./AuthActions";

@State<IAuthStateModel>({
  name: "auth",
  defaults: {
    username: null,
    fullname: null,
    roles: [],
    synced: false,
  },
})
@Injectable()
export class AuthState {

  constructor(private securityService: SecurityService) {
  }

  @Action(AuthActions.LoginAction)
  public login({setState}: StateContext<IAuthStateModel>, {credentials}: AuthActions.LoginAction) {
    return this.securityService.login(credentials).pipe(
      tap(whoami => setState({...whoami, synced: true})),
    );
  }

  @Action(AuthActions.LogoutAction)
  public logout({setState}: StateContext<IAuthStateModel>) {
    return this.securityService.logout().pipe(
      tap(() => setState({roles: [], synced: true})),
    );
  }

  @Action(AuthActions.CheckLoginStatusAction)
  public checkLoginStatus(ctx: StateContext<IAuthStateModel>) {
    return this.securityService.whoami().pipe(
      tap(whoami => ctx.setState({...whoami, synced: true})),
    );
  }
}
